import SpinnerLoader from "components/Misc/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Input, Row } from "reactstrap";
import toast from "react-hot-toast";
import { getTeamInfo, sendInvite } from "services/teamService";
import { successToast, errorToast } from "shared/constants";
import { emailRegex } from "shared/constants";
import { useHistory } from "react-router";

function Team() {
  const history = useHistory();
  const [showSpinner, setSpinner] = useState(false);
  const [team, setTeam] = useState([]);
  const [email, setEmail] = useState([]);

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = () => {
    setSpinner(true);
    getTeamInfo()
      .then((data) => {
        setTeam(
          data.filter((user) => user._id !== localStorage.getItem("user_id"))
        );
      })
      .catch((err) => {
        toast.error("Not Authorized", errorToast);
      });
    setSpinner(false);
  };

  const handleSubmit = () => {
    if (!email.toLowerCase().match(emailRegex)) {
      toast.error("Email not valid", errorToast);
    }
    sendInvite({ email })
      .then(() => {
        toast.success("Invitation Sent Successfully!", successToast);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", errorToast);
      });
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={"Team"} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="p-5">
              <Row>
                <Col sm="12">
                  <Input
                    placeholder="Enter email to send Invitation"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col sm="12">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn default-button-background text-white px-4 mt-3"
                  >
                    Send Invitation
                  </button>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col>
                  {team.map((member) => (
                    <Row key={member._id}>
                      <Col md="10">{member.email}</Col>
                      <Col md="2">
                        <button
                          type="submit"
                          onClick={() =>
                            history.push(`/admin/manage-access/${member._id}`)
                          }
                          className="btn default-button-background text-white px-4 mt-3"
                        >
                          Manage Access
                        </button>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Team;
