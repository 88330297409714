import SpinnerLoader from "components/Misc/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import toast from "react-hot-toast";
import {
  validateNodes,
  initialFeed,
  errorToast,
  successToast,
} from "shared/constants";
import { postFeed } from "services/accountsService";
import { getSelfAccountsInfo } from "services/accountsService";
import { MultiDropdown } from "components/Misc/MultiDropdown";
import _ from "lodash";
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;

function PostFeed() {
  const [showSpinner, setSpinner] = useState(false);
  const [form, setFormValue] = useState(initialFeed);
  const [showError, setErrorMessage] = useState(false);
  const [permittedUsers, setPermittedUsers] = useState();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getAccounts();
    new Dropzone(document.getElementById("dropzone-multiple"), {
      url: "...",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-multiple"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-multiple")[0]
        .innerHTML,
      maxFiles: null,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          setFiles((prev) => [...prev, file]);
        });
        this.on("removedfile", function (file) {
          setFiles((prev) => prev.filter((f) => f.name !== file.name));
        });
      },
    });
    document.getElementsByClassName("dz-preview-multiple")[0].innerHTML = "";
  }, []);

  useEffect(() => {}, [permittedUsers]);

  const getAccounts = () => {
    setSpinner(true);
    getSelfAccountsInfo().then((data) => {
      setPermittedUsers(data.permitted_accounts);
      setSpinner(false);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!validateNodes(form)) {
        setErrorMessage(true);
        return;
      }
      setSpinner(true);
      await postFeed({ ...form, files });
      toast.success("Posting Feed...", successToast);
      setSpinner(false);
      setFormValue(initialFeed);
      setErrorMessage(false);
    } catch (err) {
      console.log(err);
      toast.error("Somewthing went wrong", errorToast);
      setSpinner(false);
    }
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={"Post Feed"} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="p-5">
              {permittedUsers ? (
                <Row className="mb-5">
                  <Col md="6">
                    <h4 className="headingColor">Post with User Accounts</h4>
                    <div
                      className={
                        !form.users.length && showError ? "is-invalid" : ""
                      }
                    >
                      <MultiDropdown
                        value={form.users}
                        options={permittedUsers.map((member) => {
                          return { label: member.email, value: member._id };
                        })}
                        valueChange={(data) =>
                          setFormValue({
                            ...form,
                            ...{ users: data },
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mb-5">
                <Col md="6">
                  <Input
                    className={!form.text && showError ? "is-invalid" : ""}
                    placeholder="Share what on your mind"
                    type="textarea"
                    rows="5"
                    value={form.text}
                    onChange={(e) =>
                      setFormValue({ ...form, ...{ text: e.target.value } })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div
                    className="dropzone dropzone-multiple"
                    id="dropzone-multiple"
                  >
                    <div className="fallback">
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          id="customFileUploadMultiple"
                          multiple="multiple"
                          type="file"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileUploadMultiple"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                    <ListGroup
                      className=" dz-preview dz-preview-multiple list-group-lg"
                      flush
                    >
                      <ListGroupItem className=" px-0">
                        <Row className=" align-items-center">
                          <Col className=" col-auto">
                            <div className=" avatar">
                              <img
                                alt="..."
                                className=" avatar-img rounded"
                                data-dz-thumbnail
                                src="..."
                              />
                            </div>
                          </Col>
                          <div className=" col ml--3">
                            <h4 className=" mb-1" data-dz-name>
                              ...
                            </h4>
                            <p className=" small text-muted mb-0" data-dz-size>
                              ...
                            </p>
                          </div>
                          <Col className=" col-auto">
                            <Button size="sm" color="danger" data-dz-remove>
                              <i className="fas fa-trash" />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                </Col>
              </Row>
            </Card>

            <Row className="py-2">
              <Col>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn default-button-background text-white px-4"
                >
                  Post Feed
                </button>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PostFeed;
