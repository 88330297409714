import Login from "views/auth/Login.js";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import PostFeed from "views/post-feed/PostFeed";
import SocialAccounts from "views/social-accounts/SocialAccounts";
import SuccessAdded from "views/success/SuccessAdded";
import Team from "views/organization/Team";
import Invitation from "views/auth/Invitation";
import ManageAccess from "views/organization/ManageAccess";

export const routes = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    miniName: "FP",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/reset-password/:id",
    name: "Reset Password",
    miniName: "RP",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/accept-invite/:id",
    name: "Invitation",
    miniName: "I",
    component: Invitation,
    layout: "/auth",
  },
];

export const sidebarRoutes = [
  {
    path: "/social-accounts",
    name: "Social Accounts",
    icon: "/biomarker",
    component: SocialAccounts,
    layout: "/admin",
  },
  {
    path: "/post-feed",
    name: "Post Feed",
    icon: "/unit",
    component: PostFeed,
    layout: "/admin",
  },
  {
    path: "/added-account",
    component: SuccessAdded,
    layout: "/admin",
  },
  {
    path: "/team",
    component: Team,
    name: "Team",
    layout: "/admin",
    icon: "/unit",
  },
  {
    path: "/manage-access/:id",
    component: ManageAccess,
    name: "Manage Access",
    layout: "/admin",
    icon: "/unit",
  },
];
