import { authorizedPostCall, authorizedGetCall, postCall } from "./APIsService";

export const getTeamInfo = async () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/team/get-info`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const validateResetLink = (link) => {
  return new Promise((resolve, reject) => {
    postCall("/team/validate-invitation-link", { link })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const changePasswordWithLink = (password, link) => {
  return new Promise((resolve, reject) => {
    postCall("/team/sign-up-with-link", { link, password })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const sendInvite = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall("/team/send-invite", body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
