import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { resetPassword } from "services/authService";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";

function ForgotPassword() {
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(false);

  const handleSubmit = async () => {
    setSpinner(true);
    resetPassword(email)
      .then((data) => {
        data.success
          ? toast.success(
              "A rest link has been sent to your associated email. Click to reset your password.",
              successToast
            )
          : toast.error("User not Registered", errorToast);
        setSpinner(false);
      })
      .catch(() => {
        toast.error("Something went wrong", errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mx-4 mb-0 py-4 px-3">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-center my-4">
                    <h1 className="linkText">BX Social</h1>
                  </div>
                  <div className="text-center text-muted">
                    <p className="text-dark subhead mb-0">Forgot Password?</p>
                    <p className="forget-pass-text">
                      Enter the registered email address <br /> and we will send
                      you reset link <br />
                      to the associated email.
                    </p>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup>
                        <Input
                          placeholder="Email address"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={keyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="default-button-background w-100 py-2"
                        color="info"
                        type="button"
                        onClick={handleSubmit}
                      >
                        <h3 className="mb-0 text-white py-1">Reset Password</h3>
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <p
                        onClick={(e) => history.push("/auth/login")}
                        className="back-login px-2 mb-0"
                        role={"button"}
                      >
                        Back to Login
                      </p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ForgotPassword;
