export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const BIOMARKER_NAME = "BIOMARKER_NAME";
export const BIOMARKER_ABBREVIATION = "BIOMARKER_ABBREVIATION";
export const BIOMARKER_ALTERNATIVE_NAMES = "BIOMARKER_ALTERNATIVE_NAMES";
export const BIOMARKER_ENGLISH = "BIOMARKER_ENGLISH";
export const BIOMARKER_TYPE = "BIOMARKER_TYPE";
export const DEFAULT_UNIT = "DEFAULT_UNIT";
export const BIOMARKERS = "BIOMARKERS";
export const UNITS = "UNITS";
export const EDIT = "EDIT";
export const UNIT_NAME = "UNIT_NAME";
export const UNIT_ABBREVIATION = "UNIT_ABBREVIATION";
export const ALTERNATIVE_ABBREVIATIONS = "ALTERNATIVE_ABBREVIATIONS";
export const UNIT_CONVERSION = "UNIT_CONVERSION";
export const ITEMS_PER_PAGE = "ITEMS_PER_PAGE";
export const ADD_NEW = "ADD_NEW";
export const ADD_NEW_CONVERSION_UNIT = "ADD_NEW_CONVERSION_UNIT";
export const SIGN_OUT = "SIGN_OUT";
export const BIOMARKER_DETAILS = "BIOMARKER_DETAILS";
export const REFERENCE_RANGE_DETAILS = "REFERENCE_RANGE_DETAILS";
export const UNIT_TO_SI_CONVERSION = "UNIT_TO_SI_CONVERSION";
export const UNIT_DETAILS = "UNIT_DETAILS";
export const CONVERSION_UNITS = "CONVERSION_UNITS";
export const BIOMARKER_INFORMATION = "BIOMARKER_INFORMATION";
export const BIOMARKER_INCREASED_INFORMATION =
  "BIOMARKER_INCREASED_INFORMATION";
export const BIOMARKER_DECREASED_INFORMATION =
  "BIOMARKER_DECREASED_INFORMATION";
export const BIOMARKER_RECOMMENDATIONS = "BIOMARKER_RECOMMENDATIONS";
export const MINIMUM_AGE = "MINIMUM_AGE";
export const MAXIMUM_AGE = "MAXIMUM_AGE";
export const MINIMUM_VALUE = "MINIMUM_VALUE";
export const MAXIMUM_VALUE = "MAXIMUM_VALUE";
export const GENDER = "GENDER";
export const REFERENCE_RANGE_SYMBOL = "REFERENCE_RANGE_SYMBOL";
export const SI_UNIT = "SI_UNIT";
export const CONVERSION_FACTOR = "CONVERSION_FACTOR";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const CREATE_UNIT = "CREATE_UNIT";
export const UPDATE_BIOMARKER = "UPDATE_BIOMARKER";
export const CREATE_BIOMARKER = "CREATE_BIOMARKER";
export const CONVERTING_UNIT = "CONVERTING_UNIT";
export const UNIT_ALTERNATIVE_NAMES = "UNIT_ALTERNATIVE_NAMES";
export const SEARCH = "SEARCH";
export const BIOMARKER_UPDATE_MESSAGE = "BIOMARKER_UPDATE_MESSAGE";
export const BIOMARKER_CREATED_MESSAGE = "BIOMARKER_CREATED_MESSAGE";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const DEFAULT_UNIT_SELECTION_MESSAGE = "DEFAULT_UNIT_SELECTION_MESSAGE";
export const UNIT_UPDATE_MESSAGE = "UNIT_UPDATE_MESSAGE";
export const UNIT_CREATED_MESSAGE = "UNIT_CREATED_MESSAGE";
export const ADD_NEW_REFERENCE_RANGE = "ADD_NEW_REFERENCE_RANGE";
export const DELETE_BIOMARKER_CONVERSION_UNIT_TITLE =
  "DELETE_BIOMARKER_CONVERSION_UNIT_TITLE";
export const DELETE_BIOMARKER_CONVERSION_UNIT_MESSAGE =
  "DELETE_BIOMARKER_CONVERSION_UNIT_MESSAGE";

export const DELETE_REFERENCE_RANGE_MESSAGE = "DELETE_REFERENCE_RANGE_MESSAGE";
export const DELETE_REFERENCE_RANGE_TITLE = "DELETE_REFERENCE_RANGE_TITLE";
export const FORM_PROMPT = "FORM_PROMPT";
