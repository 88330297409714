import {
  authorizedPostCall,
  authorizedGetCall,
  authorizedPutCall,
} from "./APIsService";

export const addLinkedInAccount = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/accounts/linkedin-register`, body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const getSelfAccountsInfo = async () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/accounts/get-self-info`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const removeAccount = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/accounts/remove-account`, body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const postFeed = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/accounts/post-feed`, body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const getUserAccountsInfo = async (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/accounts/get-user-info/${id}`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const updateUserAccountsInfo = async (id, body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/accounts/update-user-info/${id}`, body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
