import SpinnerLoader from "components/Misc/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { getLinkedInRedirect } from "shared/constants";
import { getSelfAccountsInfo, removeAccount } from "services/accountsService";
import { ACCOUNT_TYPES } from "shared/constants";

function SocialAccounts() {
  const [showSpinner, setSpinner] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const addLinkedIn = () => {
    window.location = getLinkedInRedirect();
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    setSpinner(true);
    getSelfAccountsInfo().then((data) => {
      setAccounts(data?.accounts?.map((account) => account?.account_type));
      setSpinner(false);
    });
  };

  const removeOneAccount = (account) => {
    setSpinner(true);
    removeAccount(account).then(() => {
      getAccounts();
    });
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={"Social Accounts"} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="p-5">
              <Row>
                <Col md="3">
                  {accounts.includes(ACCOUNT_TYPES.linkedIn) ? (
                    <Button
                      color="danger"
                      onClick={() =>
                        removeOneAccount({ account: ACCOUNT_TYPES.linkedIn })
                      }
                    >
                      Remove LinkedIn Account
                    </Button>
                  ) : (
                    <Button onClick={addLinkedIn}>Add LinkedIn Account</Button>
                  )}
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default SocialAccounts;
