import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { addLinkedInAccount } from "services/accountsService";

function SuccessAdded() {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { code } = queryString.parse(search);
    addLinkedInAccount({
      code,
      redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
    })
      .then(() => history.push("/admin/social-accounts"))
      .catch((err) => console.log(err));
  }, []);

  return <div>SuccessAdded</div>;
}

export default SuccessAdded;
