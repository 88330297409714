import SpinnerLoader from "components/Misc/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Input, Row } from "reactstrap";
import toast from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import { MultiDropdown } from "components/Misc/MultiDropdown";
import { getTeamInfo } from "services/teamService";
import { ACCOUNT_TYPES_ENUM } from "shared/constants";
import { useParams } from "react-router";
import { getUserAccountsInfo } from "services/accountsService";
import { updateUserAccountsInfo } from "services/accountsService";

function ManageAccess() {
  const { id } = useParams();
  const [showSpinner, setSpinner] = useState(false);
  const [team, setTeam] = useState([]);
  const [accounts, setAccounts] = useState();

  useEffect(() => {
    getTeams();
    id && getUserAccounts();
  }, []);

  useEffect(() => {}, [team, accounts]);

  const getTeams = () => {
    setSpinner(true);
    getTeamInfo()
      .then((data) => {
        setTeam(data.filter((user) => user._id !== id));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", errorToast);
      });
    setSpinner(false);
  };

  const getUserAccounts = () => {
    setSpinner(true);
    getUserAccountsInfo(id)
      .then((data) => {
        setAccounts(data?.permitted_accounts ? data?.permitted_accounts : {});
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", errorToast);
      });
    setSpinner(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      updateUserAccountsInfo(id, { permitted_accounts: accounts });
      setSpinner(true);
      toast.success("Access Updated Successfully", successToast);
      setSpinner(false);
    } catch (err) {
      console.log(err);
      toast.error("Somewthing went wrong", errorToast);
      setSpinner(false);
    }
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={"Post Feed"} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="p-5">
              <Row className="mb-5">
                <Col>
                  <h4 className="headingColor">Accounts access</h4>
                  {team.length && accounts ? (
                    <MultiDropdown
                      value={accounts.filter((user) => user !== id)}
                      options={team.map((member) => {
                        return { label: member.email, value: member._id };
                      })}
                      valueChange={(data) => setAccounts(data)}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Card>

            <Row className="py-2">
              <Col>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn default-button-background text-white px-4"
                >
                  Update Access
                </button>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ManageAccess;
