export const updateToast = {
  className: "updateToast",
  iconTheme: {
    primary: "white",
    secondary: "#3a3a73",
  },
};

export const successToast = {
  className: "successToast",
  iconTheme: {
    primary: "white",
    secondary: "#76d89c",
  },
};

export const errorToast = {
  className: "errorToast",
  iconTheme: {
    primary: "white",
    secondary: "red",
  },
};

export const getLinkedInRedirect = () => {
  return encodeURI(
    `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=${process.env.REACT_APP_LINKEDIN_SCOPES}&state=any&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}`
  );
};

export const ACCOUNT_TYPES = {
  linkedIn: "LinkedIn",
};

export const ACCOUNT_TYPES_ENUM = [ACCOUNT_TYPES.linkedIn];

export const initialFeed = {
  users: [],
  text: "",
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateNodes = (object) => {
  const arr = Object.values(object);
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null) {
      return false;
    } else if (typeof arr[i] === "object") {
      if (checkNestedObject(arr[i]) === false) {
        return false;
      }
    } else if (Array.isArray(arr[i]) && arr[i].length === 0) {
      return false;
    } else if (arr[i] === "") {
      return false;
    }
  }
  return true;
};

const checkNestedObject = (object) => {
  const arr = Object.values(object);
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null) {
      return false;
    } else if (typeof arr[i] === "object") {
      if (checkNestedObject(arr[i]) === false) {
        return false;
      }
    } else if (Array.isArray(arr[i]) && arr[i].length === 0) {
      return false;
    } else if (arr[i] === "") {
      return false;
    }
  }
  return true;
};
