import classnames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
} from "reactstrap";
import { logout } from "services/authService";
import { useTranslation } from "react-i18next";
import { SIGN_OUT } from "shared/translation";

function AdminNavbar({ theme }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleLogout = (e) => {
    e.preventDefault();
    logout()
      .then(() => {
        history.push("/auth/login");
      })
      .catch((err) => {
        console.log(err);
        history.push("/auth/login");
      });
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand-end",
          { "navbar-dark bg-info-bh-dark": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <div className="mx-5">
          <UncontrolledDropdown nav>
            <DropdownToggle
              role="button"
              className="nav-link pr-0"
              color=""
              tag="a"
            >
              <img src="/admin.png" className="adminImg" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={(e) => handleLogout(e)}>
                <div className="d-flex align-items-center">
                  <img src="/signout.svg" />
                  <span className="signout">{t(SIGN_OUT)}</span>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
